<template>
  <NuxtI18nLink :to="props.link" class="markets-count-link">
    <div
      class="markets-count-wrapper"
      :class="classes"
      data-t="event-markets-count"
    >
      <hr v-if="props.isVisibleSeparator" class="separator" />
      <div class="count">
        <span class="markets-count">+{{ props.count }}</span>
        <StIcon name="arrow-right" :size="12" />
      </div>
    </div>
  </NuxtI18nLink>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    count: number
    link?: string
    isVisibleSeparator?: boolean
    size?: 'm' | 's'
  }>(),
  {
    link: '/',
    isVisibleSeparator: true,
    size: 'm',
  },
)

const classes = computed(() => [`size-${props.size}`])
</script>

<style scoped>
.markets-count-link {
  display: flex;
  text-decoration: none;
}

.separator {
  width: 1px;
  height: 8px;
  margin: 0 var(--spacing-075);

  background-color: var(--border-secondary);
  border: none;
}

.markets-count-wrapper {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-xxs-medium);
  color: var(--text-primary);

  &.size-s {
    font: var(--desktop-text-xs-medium);
  }

  &.size-m {
    .separator {
      height: 12px;
    }
  }

  &:hover {
    color: var(--text-link);
  }
}

.markets-count {
  margin-right: var(--spacing-025);
}

.count {
  display: flex;
  align-items: center;
}
</style>
